.checkout-header {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center;     /* Center items vertically */
    width: 100%;
    padding: 10px 0;
    font-size: 12px;
    font-family: Arial, sans-serif;
   
  }
  
  .step {
    color: grey;
    font-weight: bold;
    padding: 0 10px; /* Add padding for spacing between steps */
  }
  
  .step.active {
    color: #007fff; /* Highlight active step */
  }
  
  .dashed-line {
    text-align: center;
    color: grey;
    font-size: 12px;
    max-width: 150px; /* Limit width of dashed line for proper spacing */
  }
  